import React from "react"
import InfoMenu from "../components/InfoMenu"
import {
  Container,
  HeadlineContainer,
  HeroHeader,
  StickyContentContainer,
  StickyContent,
  TextSegment,
  TextBlock,
  Spacing,
  MinSpacing,
  SmSpacing,
} from "../components/layout-components"
import { graphql } from "gatsby"
import { asBg } from "../util/image"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import AufgabenVerstehen from "../lottie/Ansatz/Icn_AufgabenVerstehen.json"
import AuswahlTechnologie from "../lottie/Ansatz/Icn_AuswahlTechnologie.json"
import EffizienteEntwicklung from "../lottie/Ansatz/Icn_EffizienteEntwicklung.json"
import GemeinsamesZiel from "../lottie/Ansatz/Icn_GemeinsamesZiel.json"
import ProzesseAnalysieren from "../lottie/Ansatz/Icn_ProzesseAnalysieren.json"
import SkalierungProjekt from "../lottie/Ansatz/Icn_SkalierungdesProjekts.json"

const Ansatz: React.FC<{ data: any }> = ({ data }) => {
  const { headerBackground } = data

  const { t } = useTranslation()

  return (
    <Container>
      <Helmet>
        <title>Garage51 | {t("Ansatz")}</title>
      </Helmet>

      <HeroHeader {...asBg(headerBackground)} id="create-solutions">
        <HeadlineContainer align="left">
          <h1>
            {t("Aus Problemen")}
            <br />
            {t("Lösungen")}
            <br />
            <span className="subHeadline">{t("schaffen")}.</span>
          </h1>
        </HeadlineContainer>
      </HeroHeader>

      <TextBlock align="left" margin="0">
        <div>
          <p>
            <span>{t("GARAGE 51 ")} </span>
            {t(
              "ist ein unabhängiger Digitalisierungs- & Softwarespezialist mit Hauptsitz in Frankfurt am Main. Wir helfen führenden Unternehmen und Experten auf ihrem Gebiet, die frühen Phasen der Innovation zu meistern. 2014 gegründet, besteht unser Team aus Entwicklern, Strategen und Designern und hat sich den Ruf erworben, Komplexität zu durchbrechen und Software greifbar zu machen. Dabei achten wir immer auf eine klare, offene und freundliche Beratung, ganz unabhängig von der Größe eines Projektes."
            )}
          </p>
        </div>
      </TextBlock>

      <MinSpacing />

      <StickyContentContainer align="left" id="problem-understanding">
        <TextSegment
          align="left"
          width="45rem"
          colorTheme="primary"
          headline={t("Verstehen des Problems.")}
          text={t(
            "Nutzen Sie alte und ineffiziente Marketingsysteme oder müssen Sie gleiche Aufgaben mehrfach ausführen? Verwenden Sie Excel, um komplexe Daten auszutauschen und Systeme zu steuern? Wir können Ihnen helfen, Ihre Marketing- oder Betriebsabläufe neu zu bewerten und mühsame, fehleranfällige Aufgaben zu automatisieren, indem wir die richtige Technologie einsetzen."
          )}
          secondText={t(
            "Gemeinsam mit Ihnen analysieren wir Ihr Geschäftsmodell, formulieren eine Strategie und suchen nach potenziellen Schwachstellen in Prozessen. So entwickeln wir ein bedarfsgerechtes Konzept, speziell für Sie und Ihre individuellen Anforderungen."
          )}
        ></TextSegment>
        <StickyContent align="left" width="47rem">
          <StaticImage src="../images/ansatz.jpg" alt="" />
        </StickyContent>
      </StickyContentContainer>

      <Spacing />

      <StickyContentContainer align="right" id="design">
        <TextSegment
          align="left"
          width="45rem"
          colorTheme="primary"
          headline={t("Unser Service – persönlich und zielgenau")}
          text={t(
            "Der Markt und die Arbeitskultur haben sich in den letzten beiden Jahren so schnell und stark verändert, wie nie zuvor. Viele müssen umdenken und ihr bisheriges Business den neuen digitalen Anforderungen anpassen."
          )}
          secondText={t(
            "Dieser Prozess ist unsere Leidenschaft. Wir sind ein Team von ausgebildeten und erfahrenen Software-Spezialisten und decken mit unserem Knowhow nahezu den gesamten Technologie Markt ab. Unser Service beginnt bei dem Erfassen des Bedarfs und geht von Beratung über Konzeption und Design bis hin zur Programmierung und Support."
          )}
        ></TextSegment>
        <StickyContent align="right" width="49rem">
          <StaticImage src="../images/unser-service.jpg" alt="" />
        </StickyContent>
      </StickyContentContainer>

      <MinSpacing />

      <StickyContentContainer align="left" id="scaling">
        <TextSegment
          align="left"
          width="45rem"
          colorTheme="primary"
          headline={t("Kosteneffektive Skalierung")}
          text={t(
            "Digitales sollte die Lösung sein, nicht ein Problem werden. Sie können mit kleinen Schritten starten, um Ihre Arbeitsabläufe zu optimieren oder Sie wählen ein ganzheitliches Digitalkonzept für die Erreichung zukünftiger Unternehmensziele."
          )}
          secondText={t(
            "Sie bestimmen dabei die Stellschrauben: Wir konzipieren einen Ansatz, der Ihrem Budget und Ihren Anforderungen nachhaltig entspricht und sich in Zukunft ausbauen lässt."
          )}
          thirdText={t(
            "Mit dem Einsatz neuer Technologien können Sie die Effizienz und Skalierbarkeit Ihrer internen Prozesse steigern, die wirtschaftliche Leistungsfähigkeit Ihres Unternehmens erhöhen und neue Geschäftsmodelle entwickeln."
          )}
        ></TextSegment>
        <StickyContent align="left" width="49rem">
          <StaticImage src="../images/skalierung.jpg" alt="" />
        </StickyContent>
      </StickyContentContainer>

      <SmSpacing />
      <InfoMenu
        header={t("Der Prozess auf einen Blick")}
        id="process"
        marginBefore="medium"
        marginAfter="medium"
        items={[
          {
            label: t("Verstehen der Aufgabe"),
            text: t(
              "Unser Service beginnt mit dem Verstehen und Erfassen Ihres Bedarfs."
            ),
            anim: AufgabenVerstehen,
          },
          {
            label: t("Prozesse analysieren und überdenken"),
            text: t(
              "Gemeinsam mit Ihnen analysieren wir Ihr Geschäftsmodell und formulieren eine Strategie."
            ),
            anim: ProzesseAnalysieren,
          },
          {
            label: t("Gemeinsame Formulierung des Ziels"),
            text: t(
              "Wir konzipieren einen Ansatz, der Ihrem Budget und Ihren Anforderungen nachhaltig entspricht."
            ),
            anim: GemeinsamesZiel,
          },
          {
            label: t("Skalierung des Projektes"),
            text: t(
              "Von kleinen Schritten bis hin zu ganzheitlichen Digitalkonzepten."
            ),
            anim: SkalierungProjekt,
          },
          {
            label: t("Bedarfsgerechte Auswahl der Technologien"),
            text: t(
              "Wir setzen Technologien entsprechend Ihrer individuellen Anforderungen ein."
            ),
            anim: AuswahlTechnologie,
          },
          {
            label: t("Schlanke und effiziente Entwicklung"),
            text: t("Wir entwickeln bedarfsgerecht, effizient und nachhaltig."),
            anim: EffizienteEntwicklung,
          },
        ]}
      />

      <Footer />
    </Container>
  )
}

export default Ansatz

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "ansatz"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    headerBackground: file(
      relativePath: { eq: "background/Ansatz_Header.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }

    parkingImage: file(relativePath: { eq: "parking_img_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
